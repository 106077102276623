import React from "react";
import { Link } from "react-router-dom";

function Posts() {
  return (
    <div className="home">
      <div className="blog">
          <div className="blogPost">
            <div className="blogImg">
              <img
                className="postImg"
                src="https://rudemining.com/klv/logo.png"
                alt="Blog photo"
              />
            </div>
            <div className="blogInfo">
            <Link to="/blog/Klever-Node-Setup">
              <h2 className="blogInfoTitle">Klever Node Setup</h2>
            </Link>
              <p>
                This post is to help you set up your KLV node according to Klev Docs.
                Remember to always check Klever docs for the most up to date steps.
              </p>
              <p>We will cover the following below:</p>
                <ol>
                    <li>Hardware Requiremnts</li>
                    <li>Basic Docker Setup</li>
                    <li>Klever Node Setup</li>
                    <li>Monitoring Setup (With Sematext)</li>
                </ol>
            </div>
          </div>  
          <div className="blogPost">
              <div className="blogImg">
                <img
                  className="postImg"
                  src="https://rudemining.com/klv/logo.png"
                  alt="Blog photo"
                />
              </div>
              <div className="blogInfo">
                <Link to="/blog/Monitoring-Your-Validator">
                  <h2 className="blogInfoTitle">How To Monitor Your Node</h2>
                </Link>
                <p>If you are wondering how to monitor the insite of your Klever Node or even just the status of your 
                    Docker container, this post will help get you there. What is monitoring and why would anyone want it? We can think of 
                    monitoring as....
                </p>
              </div>
            </div>      
      </div>
    </div>
  );
}

export default Posts;