import React, { useEffect, useState } from "react";
import GaugeChart from 'react-gauge-chart'
import CircularProgress from '@mui/material/CircularProgress';

function Stats() {
  const [blockchainStats, setBlockchainStats] = useState({});
  const [nodeStats, setNodeStats] = useState({});
  const [nodeStats2, setNodeStats2] = useState({});
  const [refreshLoading, setRefreshLoading] = useState(0)
  const sleep = (ms) => { return new Promise(resolve => setTimeout(resolve, ms));}
  const axios = require('axios').default;
  const chartStyle = {
    
  }

  const getStats = async () => {
    let tmpData = {...nodeStats};
    let tmpDataNode = {};
    let tmpDataNodePercents = {};
    while(true){
      axios.get('https://rudemining.com:8080/node/statistics')
      .then(function (response) {
        // handle success
        
        let stats = response.data.data.statistics;
        //console.log(stats);
        tmpData = {
          blockNumber: stats.blockNumber,
          lastBlockTxCount: stats.lastBlockTxCount,
          averageBlockTxCount: stats.averageBlockTxCount,
          liveTPS: stats.liveTPS,
          peakTPS: stats.peakTPS,
          totalProcessedTxCount: stats.totalProcessedTxCount
        }
      })
      .catch(function (error) {
        // handle error
        console.log(`fail`)
        console.log(error);
      })
      .then(function () {
        setBlockchainStats(tmpData);
      });

      axios.get('https://rudemining.com:8080/node/status')
      .then(function (response) {
        // handle success
        
        let stats = response.data.data.metrics;
        //console.log(response.data.data.metrics);
        tmpDataNode = {
          node_type: stats.klv_node_type,
          consensus_slot_state:  stats.klv_consensus_slot_state,
          klv_consensus_state: stats.klv_consensus_state,
          accepted_blocks:  stats.klv_count_accepted_blocks,
        }
        tmpDataNodePercents = {
          mem_load: stats.klv_mem_load_percent,
          cpu_load: stats.klv_cpu_load_percent,
          network_recv: stats.klv_network_recv_percent,
          network_sent: stats.klv_network_sent_percent,
        }
      })
      .catch(function (error) {
        // handle error
        console.log(`fail`)
        console.log(error);
      })
      .then(function () {
        setNodeStats(tmpDataNode);
        setNodeStats2(tmpDataNodePercents)
      });

      for (let i = 0; i < 10; i++) {
        setRefreshLoading(i)
        //console.log(`Waiting ${i} seconds...`);
        await sleep(1000);
      }
      //await sleep(10000);
    }
  }

  useEffect(() => {
    getStats();

    return () => {
       
      };
  },[]);

  return (
    <div className="stats">
        <>
          <h4> Reloading in... </h4>
          <CircularProgress variant="determinate" value={refreshLoading * 10} />
        </>
      <h1>RudeMining Stats</h1>
      <div className="tile-container">
        {
          Object.keys(nodeStats).map((stats, index) => {
            return (
              <div className="gauge-tile" key={index}>
                <h2>{stats}</h2>
                <p> {nodeStats[stats]}</p>
              </div>
            );
          })
        }
      </div>
      <div className="tile-container">
        {
          Object.keys(nodeStats2).map((stats, index) => {
            return (
              <div key={index}>
                <h2>{stats}</h2>
                <GaugeChart id={"gauge-chart"+index}
                  style={chartStyle}
                  nrOfLevels={25} 
                  percent={nodeStats2[stats] / 100} 
                />
              </div>
            );
          })
        }
      </div>
      <h1>KleverChain Stats</h1>
      <div className="tile-container">
        {
          Object.keys(blockchainStats).map((stats, index) => {
            return (
              <div className="gauge-tile" key={index}>
                <h2>{stats}</h2>
                <p> {blockchainStats[stats]}</p>
              </div>
            );
          })
        }
      </div>
      
    </div>
  );
}

export default Stats;