import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import './App.css';
import {
  Header,
  Footer,
  Home,
  Stats,
  Blog,
  Posts,
  Post,
} from "./components";

function App() {
  return (
    <div className="App">
      <Router>
        <div className="page-container">
          <div className="content-wrap">
            <Header />
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/stats" element={<Stats />} />
              <Route path="/blog" element={<Blog />}>
                <Route path="" element={<Posts />} />
                <Route path=":postSlug" element={<Post />} />
              </Route>
            </Routes>
            <Footer />
          </div>
        </div>
      </Router>
    </div>
  );
}

export default App;
