import React, { useEffect } from "react";
import { useParams } from "react-router";

function Post() {
  let { postSlug } = useParams();

  useEffect(() => {
    // Fetch post using the postSlug
  }, [postSlug]);
function renderPost(){
  let post;
  if (postSlug === 'Klever-Node-Setup'){
    post = <div className="blogRecord">                   
              <div>
                  <div className="blogTitle"><p>How to setup your Klever node</p></div>
                  <div className="blogDate">
                  <p>February 21, 2022</p>
              </div>
                  <div className="blogMessage">
                      <p>This post is to help you set up your KLV node according to <a href="https://docs.klever.finance/klever-blockchain/how-to-run-a-node">Klev Docs</a>.  
                      Remember to always check Klever docs for the most up to date steps.</p>
                      <p>We will cover the following below:</p>
                      <ol>
                          <li>Hardware Requiremnts</li>
                          <li>Basic Docker Setup</li>
                          <li>Klever Node Setup</li>
                          <li>Monitoring Setup (With Sematext)</li>
                      </ol>
                      <h4>Hardware Requiremnts:</h4>
                      <p>Before building any validator, you need to know how much resrouces you need. i.e CPU, Memory, Hardisk Type/Size. Also, it's a good idea to know if you plan on running 
                          your node locally or though a service provide. I chose to go with <a href="http://inmotion.host/2e93009a">Inmotion Hosting</a> who provides VPS (Virtual Private Servers),
                          Cloud Servers, and Dedicated Servers. 
                      </p>
                      <h4>Basic Docker Setup:</h4>
                      <p> What is Docker? According to their website "Docker is an open platform for developing, shipping, and running applications. Docker enables you to separate your 
                          applications from your infrastructure so you can deliver software quickly. With Docker, you can manage your infrastructure in the same ways you manage your 
                          applications..." Feel free to read more at <a href="https://docs.docker.com/get-started/overview/">Docker Overview</a>. Docker is availble on desktop for Mac and 
                          Windows, and also Linux. We will be installing on CentOS 7. Full documentation found <a href="https://docs.docker.com/engine/install/centos/">here</a>.  
                      </p>
                      <ol>
                          <li>Uninstall old versions</li>
                          <li>Install using the repository</li>
                          <li>Set up the repository</li>
                          <li>Install Docker Engine</li>
                      </ol>
                      <h4>Klever Node/Validator Setup:</h4>
                      <p> The Klever node runs as a Docker container so you will need to have installed docker on your machine first before these steps. According to Klever, "Building and deploying blockchain 
                          apps should be simple, cheap, and easy for all developers to do, and that is exactly what Klever Blockchain enables. 
                          Instead of being a smart-contracts platform, Klever Blockchain will provide prebuilt and ready-to-use functionalities for developers to build decentralized applications". KleverChain will
                          consist of 21 validators per epoch and be utilize the POS (Proof Of Stake) Consensus to write new blocks to their chain. Also, they will be using a 
                          customized version of the Practical Byzantine Fault Tolerance algorithm (PBFT). 
                      </p>
                      <h5>How to create a wallet</h5>
                          <ol>
                              <li><code>mkdir wallet</code></li>
                              <li><code>docker run -it --rm --user "$(id -u):$(id -g)" \
                                  -v $(pwd)/wallet:/opt/klever-blockchain \
                                  --entrypoint=/usr/local/bin/operator kleverapp/klever-go-testnet:latest "create-wallet"
                              </code></li>
                          </ol>
                      <h5>How to run a node</h5>
                          <ol>
                              <li>Create statics directory: <code>mkdir -p $(pwd)/node/config $(pwd)/node/db $(pwd)/node/logs</code></li>
                              <li>Add Network genesis config: <code>curl -k https://backup.testnet.klever.finance/config.testnet.100007.tar.gz | tar -xz -C ./node</code></li>
                              <li>Create your validator BLS Key: <code>docker run -it --rm -v $(pwd)/node/config:/opt/klever-blockchain \
                                  --user "$(id -u):$(id -g)" \
                                  --entrypoint='' kleverapp/klever-go-testnet:latest keygenerator</code></li>
                              <li>Download and extract data backup for fast spin: <code>curl -k https://backup.testnet.klever.finance/kleverchain.latest.tar.gz | tar -xz -C ./node</code></li>
                              <li>Run your node: <code> docker run -it --rm \
                                  --user "$(id -u):$(id -g)" \
                                  --name klever-node \
                                  -v $(pwd)/node/config:/opt/klever-blockchain/config/node \
                                  -v $(pwd)/node/db:/opt/klever-blockchain/db \
                                  -v $(pwd)/node/logs:/opt/klever-blockchain/logs \
                                  --network=host \
                                  --entrypoint=/usr/local/bin/validator \
                                  kleverapp/klever-go-testnet:latest \
                                  '--log-save' '--rest-api-interface=0.0.0.0:8080'</code></li>
                              <li>To leave your node running and go to detach mode to not lock your terminal with node info, press the key combination  `CTRL + P + Q`</li>
                          </ol>
                      <h5>How to become a validator</h5>
                          <p>The below uses my wallet and fake BLSKEY. Please make sure to update with your own when setting up</p>
                          <ol> 
                              <li>Register your node as a validator: [BLSKEY] [COMMISSION] [MAX_DELEGATION] [LOGO] [REWARD_ADDR] [NAME] <code>docker run -it --rm --user "$(id -u):$(id -g)" \
                              -v $(pwd)/wallet:/opt/klever-blockchain \
                              --network=host \
                              --entrypoint=/usr/local/bin/operator \
                              kleverapp/klever-go-testnet:latest \
                              --key-file=./walletKey.pem \
                              create-validator \
                              'Your long BLSKEY found in your validator.pem file under node dir i.e 663e5e98f8...c4f98' \
                              10 150000000000 https://unsplash.com/photos/iar-afB0QQw /
                              klv10n9ngdlwdmq3jshs4au4948lwhdtevjlcf8sas9f37c9wmm3zm2qqqm3dz klv10n9ngdlwdmq3jshs4au4948lwhdtevjlcf8sas9f37c9wmm3zm2qqqm3dz RudeMining</code></li>
                              <li>Freeze KLV for staking: <code>docker run -it --rm --user "$(id -u):$(id -g)" \
                                  -v $(pwd)/wallet:/opt/klever-blockchain \
                                  --network=host \
                                  --entrypoint=/usr/local/bin/operator \
                                  kleverapp/klever-go-testnet:latest \
                                  --key-file=./walletKey.pem freeze 1500000</code></li>
                              <li>Get bucketID with freeze txhash: <code>docker run -it --rm --user "$(id -u):$(id -g)" \
                                  -v $(pwd)/wallet:/opt/klever-blockchain \
                                  --network=host \
                                  --entrypoint=/usr/local/bin/operator \
                                  kleverapp/klever-go-testnet:latest \
                                  --key-file=./walletKey.pem \
                                  tx-by-id \
                                  e9cb1fa46fa52ced38c1c7c7c9751bc5ed69010986f2d137ba5f2bff10367d95</code></li>
                              <li>Delegate/Stake frozen KLV to node with bucket ID: <code>docker run -it --rm --user "$(id -u):$(id -g)" \
                                  -v $(pwd)/wallet:/opt/klever-blockchain \
                                  --network=host \
                                  --entrypoint=/usr/local/bin/operator \
                                  kleverapp/klever-go-testnet:latest \
                                  --key-file=./walletKey.pem \
                                  delegate \
                                  klv10n9ngdlwdmq3jshs4au4948lwhdtevjlcf8sas9f37c9wmm3zm2qqqm3dz \
                                  2f4039062d77888d1a4b4fc9376afe3f51bf16801272e54e322851524f933ccc</code></li>
                              <li>That's it! Your node should now be consider as a validator once the 10 million or more KLV is staked to your node</li>
                          </ol>
                  </div>
              </div>
          </div>
  }else if(postSlug === 'Monitoring-Your-Validator' ){
    post = <div className="blogRecord">
              <div>
                  <div className="blogTitle"><p>Monitoring for your Klever node</p></div>
                  <div className="blogDate">
                  <p>February 27, 2022</p>
              </div>
                  <div className="blogMessage">
                      <p>If you are wondering how to monitor the insite of your Klever Node or even just the status of your Docker container, this post will help get you there. What is monitoring
                          and why would anyone want it? We can think of monitoring as a way of checking your cars tire air pressure with TPS or even the smoke detetector in your home. These 
                          monitoring solutions offer a way to let stake holders of computer/server/application or anything that can report a value to know whats going on over a period of time 
                          to see historic data and/or even to be alerted when a value reaches a certain threshold to make sure the proper measures are taken place. There are many differnet 
                          monitoring software solutions available curretly today such as Prometheous with Grafana, Logic Monitor, and Datadog just to name a few. Today we will be looking into
                          Sematext and installing it to monitor our system. <a href="https://sematext.com/">Sematext</a> offers many Integrations options for Application Performance Monitoring,
                          Log Monitoring, Server Monitoring, Container Monitoring, and much more! Below we will set up Sematext monitoring for our Docker Container and host. You can also find
                          their most updated guieds <a href="https://sematext.com/docs/agents/sematext-agent/containers/installation/">here</a> for your application.
                      </p>
                      <p>Setup Steps:</p>
                      <ol>
                          <li>Create a Sematext Account</li>
                          <li>Locate the intall app button in the top right corner</li>
                          <li>Run their docker command:  <code>docker run -d  --restart always --privileged -P --name st-agent \
                              -v /:/hostfs:ro \
                              -v /sys/:/hostfs/sys:ro \
                              -v /var/run/:/var/run/ \
                              -v /sys/kernel/debug:/sys/kernel/debug \
                              -v /etc/passwd:/etc/passwd:ro \
                              -v /etc/group:/etc/group:ro \
                              -e INFRA_TOKEN=YOUR_INFRA_APP_TOKEN_HERE \
                              -e REGION=US or EU \
                              sematext/agent:latest</code></li>
                          <li>Basic setup is complete!</li>
                      </ol>
                      <p>
                          Now that monitoring is setup. Update your dashboards with information and alerts you would like most!
                      </p>
                  </div>
              </div>
            </div>
  }
  return post;
}
  return (
    <div className="blogSomething">
        {renderPost()}
    </div>
  );
}

export default Post;