
import CountDown from "../countDown";

const Home = () =>  {
    return(
        <div> 
            <video autoPlay muted loop id="myVideo">
                <source src="https://klever.finance/wp-content/uploads/2022/02/Validators-Program-fixed.mp4" type="video/mp4"></source>
                Your browser does not support HTML5 video.
            </video> 
            <CountDown date={"July 1, 2022 00:00:00"} />
        </div>

    );
}

export default Home
