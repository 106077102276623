import NavigationBar from  "./navBar"

const Header = () =>  {
    return(
        <div className="header">
            <NavigationBar />
        </div>

    );
}

export default Header