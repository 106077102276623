import React from "react";
import { NavLink } from "react-router-dom";

import logoTitle from  "../../assets/logoTitle.svg"


function NavigationBar() {
  let links = [
    {name: 'Home', path: '/'}, 
    {name: 'Blog', path: '/blog'},
    {name: 'Stats', path: '/stats'}
  ];
  return (
    <div className="navigation">
      <nav className="navbar navbar-expand navbar-dark bg-dark">
        <div className="navbar-container">
            <NavLink className="navbar-brand" to="/">
              <img src={logoTitle} alt="Logo"></img>
            </NavLink>
            <ul className="navbar-nav ml-auto">
              {links.map(item => {
                return(
                <li className="nav-item" key={item.name}>
                  <NavLink className={({ isActive }) => isActive ? 'nav-link-activated' : 'nav-link-unactivated'} to={item.path}>
                    {item.name}
                  </NavLink>
              </li>
                )
              })}
            </ul>
        </div>
      </nav>
    </div>
  );
}

export default NavigationBar;