 import twitter from '../../assets/icons8-twitter.gif'

 const Footer = () => {
     return(
         <div className="footer">
             <footer className="py-5 bg-dark fixed-bottom">
                <div className="container">
                    <p className="copyright">
                        Copyright &copy; RudeMining 2022
                    </p>
                    </div>
                    <div className="socialLogo">
                    <a href="https://twitter.com/RudeMining"><img className="socialStatic" src="https://img.icons8.com/color/96/000000/twitter--v2.png" alt='Twitter'/></a>
                    <a href="https://twitter.com/RudeMining"><img className="socialActive" src={twitter} alt='Twitter'/></a>
                </div>
            </footer>
             
         </div>
     );
 }
 export default Footer