import { useEffect, useState } from "react";


const CountDown = ({date}) =>{
    const [timeLeft, setCountDown] = useState({
        years: 0,
        months: 0,
        days: 0,
        hours: 0,
        minutes: 0,
        seconds: 0
    });

    const countTime = () => {
        async function delayFunc(){
            while(true){
                const launchDate = new Date(date);
                let tmpTime = {...timeLeft};

                if(launchDate.getTime() > Date.now()){
                    // To calculate the time difference of two dates
                    const Difference_In_Time = launchDate.getTime() - Date.now(); 
                    // To calculate the no. of days between two dates
                    const Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);
                    const Hours_Left_In_Day = (Difference_In_Days % Math.floor(Difference_In_Days) * 24);
                    const Minutes_Left_In_Hour = (Hours_Left_In_Day % Math.floor(Hours_Left_In_Day) * 60);
                    const Seconds_Left_In_Minute = (Minutes_Left_In_Hour % Math.floor(Minutes_Left_In_Hour) * 60);
                    tmpTime = {
                        ...timeLeft,
                        years: '',
                        months: '',
                        days: Math.floor(Difference_In_Days),
                        hours: Math.floor(Hours_Left_In_Day),
                        minutes: Math.floor(Minutes_Left_In_Hour),
                        seconds: Math.floor(Seconds_Left_In_Minute)
                    }
                }
                setCountDown(tmpTime);
                // Test to console
                // console.log(Math.floor(Hours_Left_In_Day));
                await sleep(1000);
            }
        } 
        delayFunc(); 
    }
    
    const sleep = (ms) => { return new Promise(resolve => setTimeout(resolve, ms));}

    useEffect(() => {
        countTime();
        return () => {
            setCountDown({}); // This worked for me
          };
      },[]);

    return (
        <div className="countDown-container">
            <div className="countDown-top">
                    <p className="countNumberDays">{timeLeft.days}</p>
                    <p className="countTextDays">Days</p>
            </div>
            <div className="countDown-bottom">
                <div className="countDown-child">
                    <span>
                        <p className="countNumber">{timeLeft.hours}</p>
                        <p className="countText">Hours</p>
                    </span>
                </div>
                <div className="countDown-child">
                    <span>
                        <p className="countNumber">{timeLeft.minutes}</p>
                        <p className="countText">Minutes</p>
                    </span>
                </div>
                <div className="countDown-child"> 
                    <span>
                        <p className="countNumber">{timeLeft.seconds}</p>   
                        <p className="countText">Seconds</p>
                    </span>
                </div>
            </div>
        </div>
    );
}

export default CountDown