import React from "react";
import { Outlet } from "react-router-dom";

function Blog() {
  return (
    <div className="blogHome">
      <h1 className="blogTitle">War of Nodes - Blog</h1>
      <Outlet />
    </div>
  );
}

export default Blog;